<template>
  <div>
    <v-row dense>
      <v-col cols="12" md="6">
        <v-autocomplete
          class="c-input-small"
          v-model="id_goods"
          :items="goodsOptions"
          :placeholder="$t('labels.goods')"
          :label="$t('labels.goods')"
          :readonly="!!boxCode"
          @change="onGoodsChanged"
          dense
          outlined
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <input-qr-scan-model
          v-model="code"
          ref="codeRef"
          :label="placeholder"
          :disabled="!id_goods"
          @keyupEnter="scanInput"
        ></input-qr-scan-model>
      </v-col>
    </v-row>
    <v-alert
      class="mt-5"
      border="top"
      colored-border
      color="deep-purple accent-4"
      elevation="2"
    >
      <div class="deep-purple--text font-weight-medium text-center">
        Mã thùng: {{ boxCode }}
      </div>
      <div class="mt-5 text-center">
        <div v-for="uidCode in uidCodes" :key="`c_${uidCode}`">
          {{ uidCode }}
        </div>
      </div>
    </v-alert>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "CheckBox",
  components: {},
  data: () => ({
    code: null,
    isLoading: false,
    goodsOptions: [],
    id_goods: null,
    boxCode: null,
    uidCodes: [],
  }),
  computed: {
    itemPerPack() {
      if (!this.id_goods) {
        return 1;
      }
      const goods = [...this.goodsOptions].find(
        (g) => g.value == this.id_goods
      );
      return (goods && goods.item_per_pack) || 1;
    },
    step() {
      if (!this.boxCode) {
        return 1;
      }
      if (this.uidCodes.length == this.itemPerPack) {
        return 3;
      }
      return 2;
    },
    placeholder() {
      if (this.step == 1) {
        return "Mã thùng";
      }

      if (this.step == 3) {
        return "Quét lại mã thùng để hoàn thành";
      }

      return "Mã UID";
    },
  },
  mounted() {
    this.getGoods();
  },
  methods: {
    onGoodsChanged() {
      this.$refs.codeRef.focusInput();
      this.resetAll();
    },

    resetAll() {
      this.boxCode = null;
      this.uidCodes = [];
    },

    async getGoods() {
      const { data } = await httpClient.post(`/dc/v1/get-goods-no-present`);
      this.goodsOptions = [...data].map((item) => ({
        text: `${item.customer_goods_barcode} - ${item.name}`,
        value: item.id_goods,
        item_per_pack: item.item_per_pack,
      }));
    },

    async scanBox(code) {
      try {
        const { data } = await httpClient.post(`/dc/v1/get-box-for-check`, {
          code,
          id_goods: this.id_goods,
        });
        this.isLoading = false;
        this.code = null;

        if (data && data.id) {
          this.$vToastify.error(`Thùng đã tồn tại vui lòng để riêng`);
          document
            .getElementById("DcMaThungDaTonTaiVuiLongDeRieng_player")
            .play();
          return false;
        }

        this.boxCode = code;
        document.getElementById("success_sound_player").play();
      } catch (e) {
        this.isLoading = false;
        this.code = null;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        document.getElementById("error_sound_player").play();
      }
    },

    async scanUid(code) {
      this.isLoading = false;
      this.code = null;

      if (code == this.boxCode) {
        this.$vToastify.error("Chưa quét đủ SL sản phẩm");
        document.getElementById("DcChuaQuetDuSlSanPham_player").play();
        return false;
      }

      if (this.uidCodes.includes(code)) {
        this.$vToastify.error("Sản phẩm đã quét");
        document.getElementById("DcSanPhamDaQuet_player").play();
        return false;
      }

      this.uidCodes.push(code);
      document.getElementById("success_sound_player").play();
    },

    async saveBox(code) {
      if (this.boxCode != code) {
        this.$vToastify.error("Mã xác nhận chưa đúng");
        document.getElementById("error_sound_player").play();
        this.isLoading = false;
        this.code = null;
        return false;
      }

      try {
        await httpClient.post(`/dc/v1/save-new-box`, {
          code,
          id_goods: this.id_goods,
          uid_codes: this.uidCodes,
        });
        this.isLoading = false;
        this.code = null;
        this.resetAll();
        document.getElementById("success_sound_player").play();
      } catch (e) {
        this.isLoading = false;
        this.code = null;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        document.getElementById("error_sound_player").play();
      }
    },

    async scanInput() {
      if (!this.code) {
        return false;
      }

      let code = this.code.toLowerCase();

      if (code.includes("/")) {
        const codeSpl = code.split("/");
        code = codeSpl[codeSpl.length - 1];
      }

      let isErrorCode = false;
      const codeSp = code.split("");
      for (let i = 0; i < codeSp.length; i++) {
        const codeS = codeSp[i];
        if (!"abcdefghijklmnopqrstuvwxyz0123456789".includes(codeS)) {
          isErrorCode = true;
        }
      }

      if (isErrorCode) {
        document
          .getElementById(
            "DcLoiChuyenBanPhimSangTiengAnhRoiQuetLaiSanPham_player"
          )
          .play();
        this.$vToastify.error("Chuyển bàn phím sang Tiếng Anh");
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      switch (this.step) {
        case 1:
          await this.scanBox(code);
          break;
        case 2:
          await this.scanUid(code);
          break;
        case 3:
          await this.saveBox(code);
          break;

        default:
          break;
      }
    },
  },
};
</script>
